import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import GlobalStyle from 'theme/GlobalStyle';
import { theme as mainTheme } from 'theme/mainTheme';
import Navigation from 'components/Navigation/Navigation';
import NavigationProvider from 'contexts/NavigationContext';
import FavouritesProvider from 'contexts/FavouritesContext';
// import Cookies from 'components/Cookies/Cookies';
import { saveVistorId } from 'utils/emitMatomoEvent';
// import PromoModal from 'components/shared/PromoModal';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const MainLayout = ({ children }) => {
  React.useEffect(() => {
    saveVistorId();
  }, []);

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      {/* <PromoModal /> */}
      <NavigationProvider>
        <FavouritesProvider>
          <Navigation />
          <Wrapper id="home">{children}</Wrapper>
          {/* <Cookies /> */}
        </FavouritesProvider>
      </NavigationProvider>
    </ThemeProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MainLayout;
