import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logoIcon from 'assets/icons/logo-matexi.svg';
import logoWhiteIcon from 'assets/icons/matexi-white.svg';

const Wrapper = styled.a`
  display: block;
  width: 150px;
  cursor: pointer;
  padding: 20px 0;
  ${({ theme }) => theme.mq.lg} {
    padding: 10px 0;
    min-width: 160px;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const Logo = ({ white }) => (
  <Wrapper
    href="https://matexipolska.pl/"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    <Image src={white ? logoWhiteIcon : logoIcon} alt="logo" />
  </Wrapper>
);

Logo.propTypes = {
  white: PropTypes.bool,
};

Logo.defaultProps = {
  white: false,
};

export default Logo;
