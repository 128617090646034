import React, { useContext } from 'react';
import styled from 'styled-components';
import menuIcon from 'assets/icons/menu.svg';
import Icon from 'components/shared/Icon';
import useMedia from 'hooks/useMedia';
import { NavigationContext } from 'contexts/NavigationContext';

const MenuButton = styled.button`
  margin-left: auto;
  background: transparent;
  cursor: pointer;
  ${({ theme }) => theme.mq.xl} {
    display: none;
  }
`;

const MenuBtn = () => {
  const { openSidenav } = useContext(NavigationContext);
  const matchesXL = useMedia('(min-width: 1200px)');

  return (
    <>
      {!matchesXL && (
        <MenuButton aria-label="menu" onClick={openSidenav}>
          <Icon size={40} src={menuIcon} />
        </MenuButton>
      )}
    </>
  );
};

export default MenuBtn;
