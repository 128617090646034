import React, { useContext } from 'react';
import styled from 'styled-components';
import Content from 'components/shared/Content';
import Icon from 'components/shared/Icon';
import Logo from 'components/shared/Logo';
import Links from 'components/Navigation/Links';
import Sidenav from 'components/Navigation/Sidenav';
import useMedia from 'hooks/useMedia';
import invIcon from 'assets/icons/logo.svg';
import { NavigationContext } from 'contexts/NavigationContext';
import MenuBtn from 'components/Navigation/MenuBtn';

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.navSize.mobile};
  z-index: 100;
  transform: ${({ navHidden, theme }) =>
    navHidden ? `translate3d(0, -${theme.navSize.mobile}, 0)` : 'none'};
  transition: all ease-in-out 250ms;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.8);
  background: ${({ theme }) => theme.colors.dark100};
  ${({ theme }) => theme.mq.lg} {
    height: ${({ theme }) => theme.navSize.desktop};
    transform: ${({ navHidden, theme }) =>
      navHidden ? `translate3d(0, -${theme.navSize.desktop}, 0)` : 'none'};
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinksWrapper = styled.div`
  display: none;
  width: 100%;
  padding: 0 10px;
  ${({ theme }) => theme.mq.s} {
    display: flex;
    justify-content: space-evenly;
  }
`;

const StyledContent = styled(Content)`
  max-width: 1840px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 70px 0 30px;
  background: ${({ theme }) => theme.colors.white};
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
`;

const IconWrapper = styled.a`
  display: none;
  align-items: center;
  margin-left: 25px;
  min-width: 200px;
  height: 100%;
  ${({ theme }) => theme.mq.s} {
    display: flex;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-left: 50px;
  }
`;

const Navigation = () => {
  const { isSidenavVisible, handleClose, closeSidenav, isNavHidden } =
    useContext(NavigationContext);

  const matchesXL = useMedia('(min-width: 1200px)');

  return (
    <>
      {!matchesXL && (
        <Sidenav
          isVisible={isSidenavVisible}
          close={section => {
            closeSidenav();
            handleClose(section);
          }}
        />
      )}
      <Wrapper navHidden={isNavHidden}>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <StyledContent>
          <InnerWrapper>
            <IconWrapper href="/">
              <Icon auto src={invIcon} alt="grzybowska 37" />
            </IconWrapper>
            <LinksWrapper>
              <Links close={section => handleClose(section)} />
            </LinksWrapper>
            <MenuBtn />
          </InnerWrapper>
        </StyledContent>
      </Wrapper>
    </>
  );
};

export default Navigation;
