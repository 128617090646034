const scrollToSection = (selector, behavior = 'smooth') => {
  if (selector.includes('.') || selector.includes('#')) {
    // if (selector.includes('localization') && typeof window !== 'undefined') {
    //   let yOffset = -500;
    //   if (window.innerWidth < 768) {
    //     yOffset = -50;
    //   }
    //   const y =
    //     document.querySelector(`${selector}`).getBoundingClientRect().top +
    //     window.pageYOffset +
    //     yOffset;
    //   window.scrollTo({ top: y, behavior: 'smooth' });
    // } else {
    document.querySelector(`${selector}`).scrollIntoView({ behavior });
  } else {
    throw new Error('Invalid selector');
  }
};

export default scrollToSection;
